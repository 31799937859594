import { ConconCubeColor, CubeColor } from "../ConconCube/ConconCubeInterfaces";

export const availableCubeColors: Record<CubeColor, ConconCubeColor> = {
  [CubeColor.Red]: {
    label: "Ein schönes Rot",
    color: {
      r: 255,
      g: 55,
      b: 62,
    },
  },
  [CubeColor.Turquoise]: {
    label: "Turquoise",
    color: {
      r: 73,
      g: 226,
      b: 203,
    },
  },
  [CubeColor.White]: {
    label: "Weiß",
    color: {
      r: 255,
      g: 255,
      b: 255,
    },
  },
  [CubeColor.Darkgray]: {
    label: "Darkgray",
    color: {
      r: 28,
      g: 28,
      b: 28,
    },
  },
};
