import { ConconCubeProps, CubeColor } from "./ConconCubeInterfaces";

export enum ConconCubeState {
  CenterTurquoiseGraySmall = "CenterTurquoiseGraySmall",
  CenterTurquoiseGrayFlat = "CenterTurquoiseGrayFlat",
  CenterRedWhite = "CenterRedWhite",
  BottomRightRedGray = "BottomRightRedGray",
  CenterTurquoiseGrayInside = "CenterTurquoiseWhiteInside",
  CenterRedWhiteInside = "CenterRedGrayInside",
  TopLeftTurquoiseWhite = "TopLeftTurquoiseWhite",
  CenterRedGrayFlat = "CenterRedGrayFlat",
  CenterRedWhiteFlat = "CenterRedWhiteFlat",
}

export const availableCubeStates: Record<ConconCubeState, ConconCubeProps> = {
  [ConconCubeState.CenterTurquoiseGraySmall]: {
    size: 0.5,
    rotationSpeed: 3,
    rotationX: 0.5,
    rotationY: 1,
    rotationZ: 0,
    xOffset: 0,
    yOffset: 0,
    transparency: 0.9,
    borderColor: CubeColor.Darkgray,
    mainColor: CubeColor.Turquoise,
    secondColor: CubeColor.Darkgray,
    cubeFaceDistance: 0,
    borderRadius: 0,
    borderWidth: 1,
  },
  [ConconCubeState.CenterTurquoiseGrayFlat]: {
    size: 1.2,
    rotationSpeed: 3,
    rotationX: 0.5,
    rotationY: 1,
    rotationZ: 0,
    xOffset: 0,
    yOffset: 0,
    transparency: 0.9,
    borderColor: CubeColor.Darkgray,
    mainColor: CubeColor.Turquoise,
    secondColor: CubeColor.Darkgray,
    showFlat: true,
    cubeFaceDistance: 0,
    borderRadius: 5,
    borderWidth: 1,
  },
  [ConconCubeState.CenterRedWhite]: {
    size: 3.3,
    rotationSpeed: 3,
    rotationX: 0.5,
    rotationY: 1,
    rotationZ: 0,
    xOffset: 0,
    yOffset: 0,
    transparency: 0.8,
    borderColor: CubeColor.Darkgray,
    mainColor: CubeColor.Red,
    secondColor: CubeColor.White,
    cubeFaceDistance: 0,
    borderRadius: 0,
    borderWidth: 1,
  },
  [ConconCubeState.BottomRightRedGray]: {
    size: 1.5,
    rotationSpeed: 16,
    rotationX: 0.5,
    rotationY: 1,
    rotationZ: 0,
    xOffset: 30,
    yOffset: 10,
    transparency: 0.7,
    borderColor: CubeColor.Darkgray,
    mainColor: CubeColor.Red,
    secondColor: CubeColor.Darkgray,
    cubeFaceDistance: 0,
    borderRadius: 0,
    borderWidth: 1,
  },
  [ConconCubeState.CenterRedWhiteInside]: {
    size: 15,
    rotationSpeed: 2,
    rotationX: 0.5,
    rotationY: 1,
    rotationZ: 0,
    xOffset: 0,
    yOffset: 0,
    transparency: 1,
    borderColor: CubeColor.Red,
    mainColor: CubeColor.White,
    secondColor: CubeColor.Red,
    cubeFaceDistance: 0,
    borderRadius: 0,
    borderWidth: 1,
  },
  [ConconCubeState.CenterTurquoiseGrayInside]: {
    size: 15,
    rotationSpeed: 2,
    rotationX: 0.5,
    rotationY: 1,
    rotationZ: 0,
    xOffset: 0,
    yOffset: 0,
    transparency: 1,
    borderColor: CubeColor.Turquoise,
    mainColor: CubeColor.Darkgray,
    secondColor: CubeColor.Turquoise,
    cubeFaceDistance: 0,
    borderRadius: 0,
    borderWidth: 1,
  },
  [ConconCubeState.TopLeftTurquoiseWhite]: {
    size: 1.8,
    rotationSpeed: 3,
    rotationX: 0.5,
    rotationY: 1,
    rotationZ: 0,
    xOffset: -24,
    yOffset: -15,
    transparency: 0.9,
    borderColor: CubeColor.Darkgray,
    mainColor: CubeColor.Turquoise,
    secondColor: CubeColor.White,
    cubeFaceDistance: 0,
    borderRadius: 0,
    borderWidth: 1,
  },

  [ConconCubeState.CenterRedGrayFlat]: {
    size: 1.2,
    rotationSpeed: 3,
    rotationX: 0.5,
    rotationY: 1,
    rotationZ: 0,
    xOffset: 0,
    yOffset: 0,
    transparency: 0.9,
    borderColor: CubeColor.Darkgray,
    mainColor: CubeColor.Red,
    secondColor: CubeColor.Darkgray,
    showFlat: true,
    cubeFaceDistance: 0,
    borderRadius: 5,
    borderWidth: 1,
  },
  [ConconCubeState.CenterRedWhiteFlat]: {
    size: 1.2,
    rotationSpeed: 3,
    rotationX: 0.5,
    rotationY: 1,
    rotationZ: 0,
    xOffset: 0,
    yOffset: 0,
    transparency: 0.9,
    borderColor: CubeColor.White,
    mainColor: CubeColor.Red,
    secondColor: CubeColor.White,
    showFlat: true,
    cubeFaceDistance: 0,
    borderRadius: 5,
    borderWidth: 1,
  },
};
