import { createStyles, makeStyles } from "@material-ui/core";

export const useSectionStyles = makeStyles(() =>
  createStyles({
    section: {
      height: "100vh",
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },
    header: {
      textAlign: "center",
      fontWeight: "bold",
    },
    content: {
      zIndex: 3,
      padding: "0 5%",
    },
  })
);
