import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import useInView from "react-cool-inview";
import { SectionProps } from "./SectionInterfaces";
import { useSectionStyles } from "./SectionStyles";

function removeHash() {
  window.history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search
  );
}

const Section = (props: SectionProps): JSX.Element => {
  const { title, sectionClassName, id } = props;
  const classes = useSectionStyles();

  const { observe } = useInView({
    threshold: 0.55,
    onEnter: () => {
      if (id) {
        window.history.pushState(null, id, `#${id}`);
      } else {
        removeHash();
      }
      setTimeout(() => {
        if (
          window.location.hash === `#${id}` ||
          (!id && window.location.hash === "")
        ) {
          props.onInView(props.cubeConfiguration);
        }
      }, 150);
    },
  });

  return (
    <div
      id={id}
      ref={observe}
      className={clsx(classes.section, sectionClassName)}
    >
      <div className={classes.content}>
        <Typography variant="h2" component="h2" className={classes.header}>
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default Section;
