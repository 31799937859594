//
// Copied from https://usehooks-typescript.com/react-hook/use-interval,
//  which takes it from https://overreacted.io/making-setinterval-declarative-with-react-hooks/#just-show-me-the-code
//

import { useEffect, useRef } from "react";

export const useInterval = (
  callback: () => void,
  delay: number | null
): void => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, [delay]);
};
