import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useMainPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(3),
    },
    headline: {
      color: "red",
    },
    controls: {
      width: "25%",
      margin: "2%",
      zIndex: 4,
    },
  })
);
