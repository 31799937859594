import { createStyles, makeStyles } from "@material-ui/core";

export const useSectionListStyles = makeStyles(() =>
  createStyles({
    sectionGray: {
      backgroundColor: "#1c1c1c",
      color: "white",
    },
    sectionWhite: {
      backgroundColor: "white",
      color: "#1c1c1c",
    },
  })
);
