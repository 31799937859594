import { ConconCubeProps, CubeColor } from "../ConconCube/ConconCubeInterfaces";

function getRandomNumber(min: number, max: number): number {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

const mainColors = [CubeColor.Red, CubeColor.Turquoise];

const borderColors = [CubeColor.Darkgray, CubeColor.White];

const secondColors = [CubeColor.Darkgray, CubeColor.White];

export const getRandomCubeState = (): ConconCubeProps => ({
  size: getRandomNumber(0.5, 4),
  rotationSpeed: getRandomNumber(2, 10),
  rotationX: 0.5,
  rotationY: 1,
  rotationZ: 0,
  xOffset: getRandomNumber(-15, 20),
  yOffset: getRandomNumber(-20, 20),
  transparency: getRandomNumber(0.6, 1),
  borderColor:
    borderColors[Math.round(getRandomNumber(0, borderColors.length - 1))],
  mainColor: mainColors[Math.round(getRandomNumber(0, mainColors.length - 1))],
  secondColor:
    secondColors[Math.round(getRandomNumber(0, secondColors.length - 1))],
  borderRadius: 0,
  cubeFaceDistance: 0,
  borderWidth: 1,
});
