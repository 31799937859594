import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useInterval } from "../../../hooks/useInterval";
import { ConconCubeProps } from "./ConconCubeInterfaces";
import {
  cubeRotationIntervalUpdate,
  useConconCubeStyles,
} from "./ConconCubeStyles";

const ConconCube = (props: ConconCubeProps): JSX.Element => {
  const [rotation, setRotation] = useState(0);

  const isPortrait = useMediaQuery("(orientation: portrait)");

  useInterval(() => {
    if (props.showFlat) {
      if (rotation % 360 < 180) {
        setRotation(rotation - (rotation % 360));
      } else {
        setRotation(rotation + (360 - (rotation % 360)));
      }
      return;
    }
    setRotation(
      (currentRotation) =>
        currentRotation +
        props.rotationSpeed * (cubeRotationIntervalUpdate / 100)
    );
  }, cubeRotationIntervalUpdate);

  const classes = useConconCubeStyles({
    ...props,
    rotation,
    isPortrait,
  });

  return (
    <div className={classes.cubeContainer}>
      <div className={classes.cube}>
        <div className={classes.cubeSize}>
          <div className={clsx(classes.cubeFace, classes.cubeFaceFront)}>
            <div className={clsx(classes.lines, classes.cLine)} />
          </div>
          <div className={clsx(classes.cubeFace, classes.cubeFaceBack)}>
            <div className={clsx(classes.lines, classes.cLine)} />
          </div>
          <div className={clsx(classes.cubeFace, classes.cubeFaceRight)}>
            <div className={clsx(classes.lines, classes.oLine)} />
          </div>
          <div className={clsx(classes.cubeFace, classes.cubeFaceLeft)}>
            <div className={clsx(classes.lines, classes.oLine)} />
          </div>
          <div className={clsx(classes.cubeFace, classes.cubeFaceTop)}>
            <div className={clsx(classes.lines, classes.nLine)} />
          </div>
          <div className={clsx(classes.cubeFace, classes.cubeFaceBottom)}>
            <div className={clsx(classes.lines, classes.nLine)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConconCube;
