import { createStyles, makeStyles } from "@material-ui/core";

export const useConconCubeControlPanelStyles = makeStyles(() =>
  createStyles({
    button: {
      border: "1.5px solid black",
      boxShadow: "none",
      // color: "white",
      // backgroundColor: "#1976d2",
      padding: "6px 16px",
      borderRadius: "2px",
      margin: "5px 0",
      "&:hover": {
        // backgroundColor: "rgb(17, 82, 147)",
        boxShadow: "none",
      },
    },
    radioGroup: {
      margin: "10px 25px 10px 0",
      "& legend": {
        color: "black",
      },
    },
  })
);
