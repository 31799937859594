export enum CubeColor {
  Red = "Red",
  Turquoise = "Turquoise",
  White = "White",
  Darkgray = "Darkgray",
}

export interface ConconCubeProps {
  size: number;
  rotationSpeed: number;
  rotationX: number;
  rotationY: number;
  rotationZ: number;
  xOffset: number;
  yOffset: number;
  transparency: number;
  borderColor: CubeColor;
  mainColor: CubeColor;
  secondColor: CubeColor;
  showFlat?: boolean;
  cubeFaceDistance: number;
  borderRadius: number;
  borderWidth: number;
}

export interface ConconCubeStyleProps extends ConconCubeProps {
  rotation: number;
  isPortrait: boolean;
}

export interface ConconCubeColor {
  label: string;
  color: {
    r: number;
    g: number;
    b: number;
  };
}
