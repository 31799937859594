import { createStyles, makeStyles } from "@material-ui/core";
import { CSSProperties } from "react";
import { availableCubeColors } from "../ConconCubeControlPanel/availableCubeColors";
import { ConconCubeStyleProps } from "./ConconCubeInterfaces";

const cubeSize = 15;
const flatLogoSpacing = 0.03;
export const cubeRotationIntervalUpdate = 100;

/**
 * Without this value, the cube faces somehow have a gap.
 * This has been introduced with the switch to vmin in ec01023
 */
const opticalAdjustmentValue = 0.025;

const flatCubeFaceRotations = [
  { rotateX: 0, rotateY: 0 },
  { rotateX: 0, rotateY: 90 },
  { rotateX: 90, rotateY: 0 },
  { rotateX: 0, rotateY: 180 },
  { rotateX: 0, rotateY: -90 },
  { rotateX: -90, rotateY: 0 },
];

const cubeFacePosition = (
  showFlat: boolean | undefined,
  cubeFaceRadius: number,
  letterPosition: number
): CSSProperties["transform"] => {
  const { rotateX, rotateY } = flatCubeFaceRotations[letterPosition - 1];

  let transformAsString = `rotateY(${showFlat ? 0 : rotateY}deg)`;

  if (showFlat && window.innerWidth < window.innerHeight) {
    transformAsString += ` translateX(${
      showFlat
        ? cubeSize * (((letterPosition - 1) % 3) - 1) * (flatLogoSpacing + 1)
        : 0
    }vmin) translateY(${
      cubeSize * (letterPosition <= 3 ? 0.5 : -0.5) * (flatLogoSpacing + 1)
    }vmin)`;
  } else {
    transformAsString += ` translateX(${
      showFlat ? cubeSize * (letterPosition - 3.5) * (flatLogoSpacing + 1) : 0
    }vmin)`;
  }

  transformAsString += ` rotateX(${showFlat ? 0 : rotateX}deg) translateZ(${
    cubeSize / 2 - opticalAdjustmentValue + cubeFaceRadius
  }vmin)`;

  return transformAsString;
};

export const useConconCubeStyles = makeStyles(() =>
  createStyles({
    cubeContainer: {
      pointerEvents: "none",
      zIndex: 2,
      position: "fixed",
      top: `calc(50vh - ${cubeSize / 2}vmin)`,
      left: `calc(50vw - ${cubeSize / 2}vmin)`,
      perspective: "100vmin",
      transform: ({ xOffset, yOffset }: ConconCubeStyleProps) =>
        `translateX(calc(${xOffset}vw)) translateY(calc(${yOffset}vh))`,
      transition: "transform 1s",
      "&, & *": {
        transformStyle: "preserve-3d",
      },
    },

    cube: {
      position: "relative",
      width: `${cubeSize}vmin`,
      height: `${cubeSize}vmin`,
      transform: ({
        rotation,
        rotationX,
        rotationY,
        rotationZ,
        showFlat,
      }: ConconCubeStyleProps) =>
        showFlat
          ? `rotate3d(0.5,1,0,${rotation - (rotation % 360)}deg)`
          : `rotate3d(${rotationX},${rotationY},${rotationZ},${rotation}deg)`,
      transition: ({ showFlat }) =>
        `transform ${
          showFlat ? "0.5s" : `${cubeRotationIntervalUpdate / 1000}s linear`
        }`,
      // animation: "$rotation 6s linear infinite",
    },

    cubeSize: {
      height: "100%",
      width: "100%",
      transform: ({ size }: ConconCubeStyleProps) =>
        `scale3d(${size},${size},${size})`,
      transition: "transform 1s",
    },

    cubeFace: {
      overflow: "hidden",
      borderRadius: ({ borderRadius }) => `${borderRadius}%`,
      position: "absolute",
      width: `${cubeSize}vmin`,
      height: `${cubeSize}vmin`,
      transformStyle: "flat",
      border: ({ borderColor, borderWidth }: ConconCubeStyleProps) =>
        `${borderWidth}px solid rgb(${availableCubeColors[borderColor].color.r}, ${availableCubeColors[borderColor].color.g}, ${availableCubeColors[borderColor].color.b})`,
      lineHeight: `${cubeSize}vmin`,
      backgroundColor: ({
        transparency: transparancy,
        mainColor,
      }: ConconCubeStyleProps) =>
        `rgba(${availableCubeColors[mainColor].color.r}, ${availableCubeColors[mainColor].color.g}, ${availableCubeColors[mainColor].color.b}, ${transparancy})`,
      transition: "all 1s",
    },

    cubeFaceFront: {
      transform: ({ showFlat, cubeFaceDistance }) =>
        cubeFacePosition(showFlat, cubeFaceDistance, 1),
    },
    cubeFaceRight: {
      transform: ({ showFlat, cubeFaceDistance }) =>
        cubeFacePosition(showFlat, cubeFaceDistance, 2),
    },
    cubeFaceTop: {
      transform: ({ showFlat, cubeFaceDistance }) =>
        cubeFacePosition(showFlat, cubeFaceDistance, 3),
    },
    cubeFaceBack: {
      transform: ({ showFlat, cubeFaceDistance }) =>
        cubeFacePosition(showFlat, cubeFaceDistance, 4),
    },
    cubeFaceLeft: {
      transform: ({ showFlat, cubeFaceDistance }) =>
        cubeFacePosition(showFlat, cubeFaceDistance, 5),
    },
    cubeFaceBottom: {
      transform: ({ showFlat, cubeFaceDistance }) =>
        cubeFacePosition(showFlat, cubeFaceDistance, 6),
    },

    lines: {
      position: "relative",
      backgroundColor: ({ secondColor }: ConconCubeStyleProps) =>
        `rgb(${availableCubeColors[secondColor].color.r}, ${availableCubeColors[secondColor].color.g}, ${availableCubeColors[secondColor].color.b})`,
      transition: "all 1s",
    },

    cLine: {
      width: "50%",
      height: "23%",
      left: "50%",
      top: "38.5%",
    },
    oLine: {
      width: "23%",
      height: "23%",
      left: "38.5%",
      top: "38.5%",
    },
    nLine: {
      width: "23%",
      height: "50%",
      left: "38.5%",
      top: "50%",
    },
  })
);
