import React from "react";
import {
  availableCubeStates,
  ConconCubeState,
} from "../../01-atoms/ConconCube/availableCubeStates";
import Section from "../../02-molecules/Section/Section";
import { SectionProps } from "../../02-molecules/Section/SectionInterfaces";
import { SectionListProps } from "./SectionListInterfaces";
import { useSectionListStyles } from "./SectionListStyles";

const SectionList = ({ onInView }: SectionListProps): JSX.Element => {
  // const viewModel = useSectionListViewModel(props);
  const classes = useSectionListStyles();

  const sections: Omit<SectionProps, "onInView">[] = [
    {
      title: "Stream",
      id: undefined,
      sectionClassName: classes.sectionGray,
      cubeConfiguration:
        availableCubeStates[ConconCubeState.CenterTurquoiseGrayFlat],
    },
    {
      title: "Content",
      id: "content",
      sectionClassName: classes.sectionWhite,
      cubeConfiguration: availableCubeStates[ConconCubeState.CenterRedWhite],
    },
    {
      title: "About Us",
      id: "aboutus",
      sectionClassName: classes.sectionGray,
      cubeConfiguration:
        availableCubeStates[ConconCubeState.BottomRightRedGray],
    },
    {
      title: "Timetable",
      id: "timetable",
      sectionClassName: classes.sectionWhite,
      cubeConfiguration:
        availableCubeStates[ConconCubeState.CenterRedWhiteInside],
    },
    {
      title: "Content Playground Award",
      id: "contentplaygroundaward",
      sectionClassName: classes.sectionGray,
      cubeConfiguration:
        availableCubeStates[ConconCubeState.CenterTurquoiseGrayInside],
    },
    {
      title: "Partnerlogos",
      id: "partnerlogos",
      sectionClassName: classes.sectionWhite,
      cubeConfiguration:
        availableCubeStates[ConconCubeState.TopLeftTurquoiseWhite],
    },
    {
      title: "Footer",
      id: "footer",
      sectionClassName: classes.sectionGray,
      cubeConfiguration: availableCubeStates[ConconCubeState.CenterRedGrayFlat],
    },
  ];

  return (
    <>
      {sections.map((section, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Section key={index} {...section} onInView={onInView} />
      ))}
    </>
  );
};

export default SectionList;
