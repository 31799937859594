import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from "@material-ui/core";
import { Button } from "gatsby-material-ui-components";
import React from "react";
import {
  availableCubeStates,
  ConconCubeState,
} from "../ConconCube/availableCubeStates";
import { ConconCubeProps, CubeColor } from "../ConconCube/ConconCubeInterfaces";
import { availableCubeColors } from "./availableCubeColors";
import {
  ConconCubeControlPanelProps,
  RadioColorGroup,
  SliderOptions,
} from "./ConconCubeControlPanelInterfaces";
import { useConconCubeControlPanelStyles } from "./ConconCubeControlPanelStyles";
import { getRandomCubeState } from "./getRandomCubeState";

Math.round(("hello" as unknown) as number);

const ConconCubeControlPanel = ({
  onCubeSettingChange,
  currentCubeState,
}: ConconCubeControlPanelProps): JSX.Element => {
  const { showFlat } = currentCubeState;

  const setCubeProperty = <PropertyKey extends keyof ConconCubeProps>(
    property: PropertyKey,
    newValue: ConconCubeProps[PropertyKey]
  ) => {
    onCubeSettingChange({
      ...currentCubeState,
      [property]: newValue,
    });
  };

  const handleDemoButtonClick = (
    button: "first" | "second" | "third" | "random"
  ) => {
    if (button === "first") {
      onCubeSettingChange(
        availableCubeStates[ConconCubeState.CenterRedWhiteFlat]
      );
    } else if (button === "second") {
      onCubeSettingChange(
        availableCubeStates[ConconCubeState.TopLeftTurquoiseWhite]
      );
    } else if (button === "third") {
      onCubeSettingChange(
        availableCubeStates[ConconCubeState.CenterRedWhiteInside]
      );
    } else if (button === "random") {
      onCubeSettingChange(getRandomCubeState());
    }
  };

  const classes = useConconCubeControlPanelStyles();

  const radioColorGroups: RadioColorGroup[] = [
    {
      label: "Main Color",
      propertyAsString: "mainColor",
    },
    {
      label: "Secondary Color",
      propertyAsString: "secondColor",
    },
    {
      label: "Border Color",
      propertyAsString: "borderColor",
    },
  ];

  const sliders: SliderOptions[] = [
    {
      label: "Size",
      propertyAsString: "size",
      step: 0.1,
      min: 0.1,
      max: 15,
    },
    {
      label: "Rotation Speed",
      propertyAsString: "rotationSpeed",
      step: 0.1,
      min: 0,
      max: 50,
    },
    {
      label: "X Offset",
      propertyAsString: "xOffset",
      step: 1,
      min: -50,
      max: 50,
    },
    {
      label: "Y Offset",
      propertyAsString: "yOffset",
      step: 1,
      min: -50,
      max: 50,
    },
    {
      label: "Transparency",
      propertyAsString: "transparency",
      step: 0.01,
      min: 0,
      max: 1,
    },
    {
      label: "Border Radius",
      propertyAsString: "borderRadius",
      step: 1,
      min: 0,
      max: 100,
    },
    {
      label: "Border Width",
      propertyAsString: "borderWidth",
      step: 1,
      min: 0,
      max: 10,
    },
    {
      label: "Cube Face Distance",
      propertyAsString: "cubeFaceDistance",
      step: 0.1,
      min: 0,
      max: 20,
    },
    {
      label: "Rotation Axis X",
      propertyAsString: "rotationX",
      step: 0.1,
      min: 0,
      max: 1,
    },
    {
      label: "Rotation Axis Y",
      propertyAsString: "rotationY",
      step: 0.1,
      min: 0,
      max: 1,
    },
    {
      label: "Rotation Axis Z",
      propertyAsString: "rotationZ",
      step: 0.1,
      min: 0,
      max: 1,
    },
  ];

  return (
    <div>
      <Button
        className={classes.button}
        fullWidth
        variant="outlined"
        onClick={() => setCubeProperty("showFlat", !showFlat)}
      >
        toggle logo
      </Button>
      {sliders.map(({ label, step, propertyAsString, min, max }) => (
        <div key={label}>
          <Typography id="continuous-slider" gutterBottom>
            {label}
          </Typography>
          <Slider
            defaultValue={1}
            value={currentCubeState[propertyAsString] as number}
            aria-labelledby="discrete-slider"
            onChange={(e, newVal) =>
              setCubeProperty(propertyAsString, newVal as number)
            }
            valueLabelDisplay="auto"
            step={step}
            min={min}
            max={max}
          />
        </div>
      ))}
      {radioColorGroups.map(
        ({ label, propertyAsString }: RadioColorGroup, index) => (
          <FormControl
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            component="fieldset"
            className={classes.radioGroup}
          >
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
              value={currentCubeState[propertyAsString]}
              onChange={(e, newVal) => {
                setCubeProperty(propertyAsString, newVal as CubeColor);
              }}
            >
              {Object.entries(availableCubeColors).map((entry) => {
                return (
                  <FormControlLabel
                    key={entry[0]}
                    value={entry[0]}
                    control={<Radio color="primary" />}
                    label={entry[1].label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        )
      )}
      <Button
        className={classes.button}
        fullWidth
        variant="outlined"
        onClick={() => {
          handleDemoButtonClick("first");
        }}
      >
        Flat Logo
      </Button>
      <Button
        className={classes.button}
        fullWidth
        variant="outlined"
        onClick={() => {
          handleDemoButtonClick("second");
        }}
      >
        Turquoise
      </Button>
      <Button
        className={classes.button}
        fullWidth
        variant="outlined"
        onClick={() => {
          handleDemoButtonClick("third");
        }}
      >
        Inside red cube
      </Button>
      <Button
        className={classes.button}
        fullWidth
        variant="outlined"
        onClick={() => {
          handleDemoButtonClick("random");
        }}
      >
        random
      </Button>
    </div>
  );
};

export default ConconCubeControlPanel;
