import React, { useCallback, useState } from "react";
import { PageLayout } from "../../../layouts/pageLayout/PageLayout";
import {
  availableCubeStates,
  ConconCubeState,
} from "../../01-atoms/ConconCube/availableCubeStates";
import ConconCube from "../../01-atoms/ConconCube/ConconCube";
import { ConconCubeProps } from "../../01-atoms/ConconCube/ConconCubeInterfaces";
import ConconCubeControlPanel from "../../01-atoms/ConconCubeControlPanel/ConconCubeControlPanel";
import SectionList from "../../03-organisms/SectionList/SectionList";
import { useMainPageStyles } from "./MainPageStyles";

interface MainPageProps {
  displayMode: "controls" | "sections";
}

const MainPage = (props: MainPageProps): JSX.Element => {
  const classes = useMainPageStyles();

  const [cubeState, setCubeState] = useState<ConconCubeProps>(
    availableCubeStates[ConconCubeState.CenterTurquoiseGraySmall]
  );

  const cubeChange = useCallback((cubeProps: ConconCubeProps) => {
    setCubeState(cubeProps);
  }, []);

  function DisplayComponent() {
    return props.displayMode === "controls" ? (
      <div className={classes.controls}>
        <ConconCubeControlPanel
          onCubeSettingChange={cubeChange}
          currentCubeState={cubeState}
        />
      </div>
    ) : (
      <SectionList onInView={cubeChange} />
    );
  }

  return (
    <PageLayout>
      <ConconCube {...cubeState} />
      <DisplayComponent />
    </PageLayout>
  );
};

export default MainPage;
